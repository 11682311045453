<template>
    <v-lazy
        :v-model="image"
        :options="{
            threshold: 0.5,
        }"
        class="fill-height"
    >
        <v-hover v-slot="{ hover }">
            <v-card
                @click="show(image.id)"
                :elevation="hover ? 5 : 0"
                :class="{ 'on-hover': hover }"
                max-height="400"
            >
                <v-img
                    :src="image320x320URL"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                            ></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>

                <v-card-title class="caption text-capitalize m-0">
                    <v-chip
                        class="mr-1"
                        x-small
                        flat
                        v-for="(tag, i) in imageToUpdate.tags"
                        :key="i"
                        >{{ tag.toLowerCase() }}</v-chip
                    >
                    <v-spacer></v-spacer>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item @click.stop="editTags(image)">
                                <v-dialog
                                    v-model="editDialog"
                                    persistent
                                    max-width="400px"
                                    :retain-focus="false"
                                >
                                    <v-card :loading="loading">
                                        <v-card-title class="text-h5">
                                            Edit Tags</v-card-title
                                        >
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12">
                                                    <div
                                                        class="d-flex tag-input align-center flex-wrap"
                                                    >
                                                        <v-chip
                                                            @click:close="
                                                                removeTag(index)
                                                            "
                                                            v-for="(tag,
                                                            index) in imageToUpdate.tags"
                                                            :key="index"
                                                            close
                                                            class=""
                                                        >
                                                            {{ tag }}
                                                        </v-chip>

                                                        <v-text-field
                                                            @keydown.enter="
                                                                addTag
                                                            "
                                                            @keydown.space="
                                                                addTag
                                                            "
                                                            @keydown.delete="
                                                                removeLastTag
                                                            "
                                                            dense
                                                            solo
                                                            hide-details
                                                            v-model="tag"
                                                            flat
                                                            label="Enter a Tag"
                                                        >
                                                        </v-text-field>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn
                                                text
                                                color="secondary"
                                                @click="editDialog = false"
                                            >
                                                Close
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                :loading="loading"
                                                @click="updateImage(index)"
                                            >
                                                Update
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-list-item-title>Edit Tags</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click.stop="openDelete(image)">
                                <v-dialog
                                    v-model="deleteDialog"
                                    persistent
                                    max-width="400px"
                                    :retain-focus="false"
                                >
                                    <v-card :loading="loading">
                                        <v-card-title class="text-h5"
                                            >Delete Image</v-card-title
                                        >
                                        <v-card-text>
                                            Are you sure you want to delete this
                                            image?
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn
                                                text
                                                color="secondary"
                                                @click="deleteDialog = false"
                                            >
                                                Close
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                @click="deleteImage"
                                                text
                                                color="error"
                                                :loading="loading"
                                            >
                                                Delete
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-list-item-title>
                                    Delete
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="downloadFile(image)">
                                <v-list-item-title>Download</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-card-title>
            </v-card>
        </v-hover>
    </v-lazy>
</template>
<script>
import { storage } from '@/services/firebase'
import _ from 'lodash'
export default {
    name: 'ToolImageCard',
    props: {
        index: { type: Number },
        totalImages: { type: Number },
        image: {
            type: Object,
            default() {
                return {
                    id: '',
                    tags: '',
                    file: '',
                    file320x320: null,
                    image320x320URL: null,
                }
            },
        },
    },
    data() {
        return {
            deleteDialog: false,
            editDialog: false,
            tag: '',
            loading: null,
            folderPictures: 'photo_galery',
            image320x320URL: null,
            imageToUpdate: null,
        }
    },
    async created() {
        try {
            this.imageToUpdate = this.image
            if (this.image.file320x320) {
                const companyId = JSON.parse(localStorage.getItem('company'))
                const path = `${companyId}/${this.folderPictures}`
                const storageRef = storage().ref(path)
                const picRef = storageRef.child(this.image.file320x320)
                await picRef.getDownloadURL().then(URL => {
                    this.image.image320x320URL = URL
                    this.image320x320URL = URL
                })
                const fileRef = storageRef.child(this.image.file)
                await fileRef.getDownloadURL().then(URL => {
                    this.image.imageURL = URL
                    if (
                        this.index == 19 ||
                        this.index == this.totalImages - 1
                    ) {
                        this.$emit('firstLoad')
                    }
                })
            }
        } catch (error) {
            this.$emit('removeImage', this.image)
            error && true
        }
    },
    mounted() {},
    methods: {
        async downloadFile() {
            const companyId = JSON.parse(localStorage.getItem('company'))
            const path = `${companyId}/${this.folderPictures}`
            const storageRef = storage().ref(path)
            const picRef = storageRef.child(this.image.file)
            await picRef
                .getDownloadURL()
                .then(URL => {
                    this.image.imageURL = URL
                    window.open(this.image.imageURL, '_blank')
                })
                .catch(error => {
                    console.log(error.message)
                    error && true
                })
        },
        openDelete() {
            this.deleteDialog = true
        },
        updateImage() {
            this.$emit('updateImage', this.index, this.imageToUpdate)
            this.imageToUpdate = _.cloneDeep(this.imageToUpdate)
            this.editDialog = false
        },
        editTags() {
            this.imageToUpdate = this.image
            this.editDialog = true
        },
        addTag(event) {
            event.preventDefault()
            var val = event.target.value.trim().toLowerCase()
            if (val.length > 0) {
                this.imageToUpdate.tags.push(val)
                event.target.value = ''
                this.tag = ''
            }
        },
        removeTag(index) {
            this.imageToUpdate.tags.splice(index, 1)
        },
        removeLastTag(event) {
            if (event.target.value.length === 0) {
                this.removeTag(this.imageToUpdate.tags.length - 1)
            }
        },
        deleteImage() {
            this.$emit('deleteImage', this.image)
        },
        show() {
            this.$emit('show', this.index)
        },
    },
}
</script>
